var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-popover",
    {
      attrs: {
        placement: "left",
        "get-popup-container": function (triggerNode) {
          return triggerNode.parentNode
        },
        "overlay-style": {
          padding: 0,
        },
      },
    },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _c("div", { staticClass: "network-info" }, [
          _vm.quality.score
            ? _c("div", { staticClass: "network-info-item" }, [
                _c("div", { staticClass: "network-info-item-label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("ytalk.networkInfo.score")) +
                      ":\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { style: { color: _vm.quality.color } }, [
                  _vm._v(_vm._s(_vm.quality.score)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.quality.sipLostRate
            ? _c("div", { staticClass: "network-info-item" }, [
                _c("div", { staticClass: "network-info-item-label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("ytalk.networkInfo.sipLostRate")) +
                      ":\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.quality.sipLostRate))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.quality.sipRTT
            ? _c("div", { staticClass: "network-info-item" }, [
                _c("div", { staticClass: "network-info-item-label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("ytalk.networkInfo.sipRTT")) +
                      ":\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.quality.sipRTT))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.quality.rtpLostRate
            ? _c("div", { staticClass: "network-info-item" }, [
                _c("div", { staticClass: "network-info-item-label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("ytalk.networkInfo.rtpLostRate")) +
                      ":\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.quality.rtpLostRate))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.quality.rtpRTT
            ? _c("div", { staticClass: "network-info-item" }, [
                _c("div", { staticClass: "network-info-item-label" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.$t("ytalk.networkInfo.rtpRTT")) +
                      ":\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.quality.rtpRTT))]),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "network-quality", style: { cursor: "pointer" } },
        [_c("div", [_c("wifi", { attrs: { signal: _vm.quality.level } })], 1)]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }