<template>
    <a-popover
        placement="left"
        :get-popup-container="(triggerNode) => triggerNode.parentNode"
        :overlay-style="{
            padding: 0
        }"
    >
        <div slot="content">
            <div class="network-info">
                <div
                    v-if="quality.score"
                    class="network-info-item"
                >
                    <div class="network-info-item-label">
                        {{ $t('ytalk.networkInfo.score') }}:
                    </div>
                    <span :style="{color: quality.color}">{{ quality.score }}</span>
                </div>
                <div
                    v-if="quality.sipLostRate"
                    class="network-info-item"
                >
                    <div class="network-info-item-label">
                        {{ $t('ytalk.networkInfo.sipLostRate') }}:
                    </div>
                    <span>{{ quality.sipLostRate }}</span>
                </div>
                <div
                    v-if="quality.sipRTT"
                    class="network-info-item"
                >
                    <div class="network-info-item-label">
                        {{ $t('ytalk.networkInfo.sipRTT') }}:
                    </div>
                    <span>{{ quality.sipRTT }}</span>
                </div>
                <div
                    v-if="quality.rtpLostRate"
                    class="network-info-item"
                >
                    <div class="network-info-item-label">
                        {{ $t('ytalk.networkInfo.rtpLostRate') }}:
                    </div>
                    <span>{{ quality.rtpLostRate }}</span>
                </div>
                <div
                    v-if="quality.rtpRTT"
                    class="network-info-item"
                >
                    <div class="network-info-item-label">
                        {{ $t('ytalk.networkInfo.rtpRTT') }}:
                    </div>
                    <span>{{ quality.rtpRTT }}</span>
                </div>
            </div>
        </div>
        <div
            class="network-quality"
            :style="{cursor: 'pointer'}"
        >
            <div>
                <wifi :signal="quality.level" />
            </div>
        </div>
    </a-popover>
</template>

<script type="text/babel">
    import Wifi from './wifi';

    export default {
        name: 'NetworkInfo',

        components: {
            Wifi
        },

        props: {
            quality: {
                type: Object,
                default: () => ({}),
            },
        },
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .network-quality {
        display: inline-flex;
        justify-content: left;
        align-items: center;
    }

    .network-info {
        width: 170px;
    }

    .network-info-item {
        text-align: center;
        margin: 5px;
        display: flex;

        .network-info-item-label {
            width: 80px;
            text-align: left;
        }
    }
</style>
