
/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 转接
 */

import EnumAll from 'collection-admin-web/common/constant/enum';

import mixinGetCaseDetail from '../../mixin/get-case-detail';
import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class TransferInboundType extends BasicType {

    type = 'TRANSFER_INBOUND';

    constructor(props) {
        super(props);

        this.title = '【客服转接】';
        this.encryptedMobileNumber = this.sharedData.incomingEncryptedMobileNumber;

        this.extraData.dialType = CallDialType.TRANSFER;
        this.extraData.encryptedMobileNumber = this.encryptedMobileNumber;
    }

    /* @desc override */
    getSharedData() {
        const {sharedDataRet} = this;

        return sharedDataRet.promise;
    }

}

Object.assign(TransferInboundType.prototype, mixinGetCaseDetail);
