<template>
    <WujieVue
        :name="name"
        :url="pageUrl"
        :sync="true"
        :props="wujieProps"
    />
</template>

<script>
import WujieVue from 'wujie-vue2';

export default {
    name: 'WuJie',
    components: {
        WujieVue
    },
    props: {
        pageUrl: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: '',
        },
        wujieProps: {
            type: Object,
            default: () => ({}),
        }
    }
};
</script>
