/*
 * @Author: huayizhang 
 * @Date: 2021-05-10 15:07:35 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-20 00:03:51
 */

export const BooleanType = {
    TRUE: 'TRUE',
    FALSE: 'FALSE'
};

export const BooleanTypeMap = {
    [BooleanType.TRUE]: '是',
    [BooleanType.FALSE]: '否'
};

export const Boolean = {
    true: 'true',
    false: 'false'
};

export const BooleanMap = {
    [Boolean.true]: '是',
    [Boolean.false]: '否'
};

export const AvailableMap = {
    [BooleanType.TRUE]: '启用',
    [BooleanType.FALSE]: '禁用'
};

export const AlwaysAvailableMap = {
    [BooleanType.TRUE]: '永久',
    [BooleanType.FALSE]: '短期'
};
