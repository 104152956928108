/*
 * @Author: wenxiujiang
 * @Date: 2022-11-01 13:28:41
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-11-12 16:29:53
 */
export default {
    common: {
        add: '新增',
        addCategory: '新增短信类型',
        addNotifSuccess: '短信类型已成功添加！',
        cancel: '取消',
        checkAll: '全选',
        chooseFile: '选择文件',
        close: '关闭',
        confirm: '确定',
        confirmDelete: '确认删除',
        copy: '复制',
        create: '添加',
        delete: '删除',
        edit: '编辑',
        fileUrl: '文件地址',
        format: '格式化',
        formatFailed: '格式化失败',
        imageUrl: '图片地址',
        noData: '暂无数据',
        op: '操作',
        uploadFile: '上传文件',
        uploadImage: '上传图片',
        uploadLocal: '本地上传',
        imageDesc: '图片描述',
        fileDesc: '文件描述',
        pleaseSelect: '请选择',
        query: '查询',
        reset: '重置',
        timeCreate: '创建时间',
        timeUpdate: '更新时间',
        uploadAudio: '上传音频',
        modifyPassword: '修改密码',
        logout: '登出',
        pleaseInput: '请输入'
    },
    pagination: {
        line: '行',
        total: '共'
    },
    rich: {
        auto: '自动',
        addColumnBefore: '前加列',
        addColumnAfter: '后加列',
        addRowBefore: '前加行',
        addRowAfter: '后加行',
        backgroundColor: '背景色',
        blockquote: '引用',
        bold: '加粗',
        bulletList: '无序列表',
        clear: '清除',
        code: '代码',
        codeBlock: '代码块',
        deleteColumn: '删除列',
        deleteRow: '删除行',
        deleteTable: '删除表格',
        file: '文件',
        fontColor: '字体色',
        fontSize: '字体大小',
        h1: '标题1',
        h2: '标题2',
        h3: '标题3',
        h4: '标题4',
        h5: '标题5',
        h6: '标题6',
        horizontalRule: '横线',
        image: '图片',
        insertLink: '插入链接',
        italic: '斜体',
        link: '链接',
        linkUrl: '链接地址',
        linkDesc: '链接描述',
        orderedList: '有序列表',
        paragraph: '段落',
        primaryColor: '主题颜色',
        redo: '重做',
        replace: '替换',
        replaceAll: '全部替换',
        search: '查找',
        standardColor: '标准色',
        strike: '中划线',
        table: '表格',
        tac: '居中',
        tal: '左对齐',
        tar: '右对齐',
        toggleCellMerge: '合并单元格',
        underline: '下划线',
        undo: '撤回',
        audio: '音频'
    },
    file: {
        preview: '预览',
        noGreaterThan: '不大于{0}',
        noLessThan: '不小于{0}',
        acceptTypes: '仅支持{0}格式',
        empty: '文件不能为空，请重新上传',
        onlyImage: '仅支持上传图片',
        image: '图片',
        file: '文件',
        size: '当前{fileType}大小为{curSize}，请上传大小为{size}的{fileType}',
        error: '文件有误，请重新上传'
    },
    rule: {
        required: '必填'
    },
    table: {
        searchPlaceholder: '表内搜索',
        clientSortTip: '当前排序仅针对本页数据，并非所有数据',
        exportCurrentPage: '导出本页',
        searchLoading: '表格加载中'
    },
    ytalk: {
        warning: '警告',
        info: '提醒',
        verifyInfo: '不支持当前浏览器版本，外呼无法正常使用！',
        initializeFailed: '外呼初始化失败',
        microphoneEnabled: '请打开浏览器麦克风权限，并检查音频输入设备',
        pageClosed: '确定关闭页面?',
        loginSuccess: '外呼登录成功',
        logoutSuccess: '外呼登出成功',
        registerTips: '当前坐席已在其他设备或浏览器登录，如果频繁出现请尝试关闭浏览器重新登录。',
        callTest: {
            title: '通话检测',
            tipText1: '检测说明：',
            tipText2: '请尝试说几句话，判断耳机里能否听到你刚才说话的声音。如果能听到，代表麦克风、扬声器、网络没有问题。',
            tipText3: '无声解决方案：',
            tipText4: '请切换麦克风或者扬声器设备后重试。如还未解决，请反馈管理员。如果切换设备后能够听到声音，请在电脑设置中调整为对应设备。',
            testing: '回声测试中',
            microphone: '麦克风',
            inputDeviceName: '输入设备名称：',
            speaker: '扬声器',
            outputDeviceName: '输出设备名称：',
            initModal: {
                title: '音频设备发生变化，将重新检测设备',
            },
            systemErrorModal: {
                title: '系统异常 {0}',
                content: '请关闭通话检测窗口，重新检测',
                okText: '重新检测',
                cancelText: '关闭'
            },
            warn: {
                testInMainTab: '请到通话主页面中进行通话检测!'
            },
            error: {
                noAudioDevice: '未检测到音频设备',
                noMicrophone: '未检测到麦克风',
                noSpeaker: '未检测到扬声器',
                noType: 'type不存在',
                noCallAccountNumber: 'callAccountNumber不存在',
                BrowserNotSupported: '浏览器不支持访问用户媒体设备，请升级或更换浏览器',
                AbortError: '操作中止',
                NotAllowedError: '打开设备权限不足，原因是用户拒绝了媒体访问请求',
                NotFoundError: '找不到满足条件的设备',
                NotReadableError: '系统上某个硬件、浏览器或网页层面发生的错误导致设备无法被访问',
                OverConstrainedError: '指定的要求无法被设备满足',
                SecurityError: '安全错误，使用设备媒体被禁止',
                TypeError: '类型错误',
                NotSupportedError: '不支持的操作',
                NetworkError: '网络错误发生',
                TimeoutError: '操作超时',
                UnknownError: '因未知的瞬态的原因使操作失败)',
                ConstraintError: '条件没满足而导致事件失败的异常操作',
            },
            message: {
                'permission denied': '麦克风、摄像头权限未开启，请检查后重试',
                'requested device not found': '未检测到摄像头',
                'could not start video source': '无法访问到摄像头',
                'unknownError': '未知错误'
            }
        },
        networkInfo: {
            score: '总分数',
            sipLostRate: 'sip 丢包率',
            sipRTT: 'sip 延迟',
            rtpLostRate: 'rtp 丢包率',
            rtpRTT: 'rtp 延迟'
        }
    }
};
