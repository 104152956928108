<!-- @Author: yefenghan -->
<!-- @Date: 2024-9-24 15:51:28 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-09-24 15:51:42 -->
<template>
    <div class="unmask-part-mobile">
        <slot :mobile="mobile">
            <span class="mr5">{{ mobile }}</span>
        </slot>
        <a-icon
            v-if="$app.permissions.EXTRA_DISPLAY_SELF_MOBILE && canUnMask"
            :type="visible ? 'eye' : 'eye-invisible'"
            @click="onVisibleChange"
        />
    </div>
</template>

<script>
import UnMaskResource from 'collection-admin-web/common/resource/collection-reconcile';

export default {
    name: 'YqgUnmaskPartMobile',
    props: {
        maskedMobile: {
            type: String,
            required: true
        },
        unmaskNavigate: {
            type: String,
            required: true
        },
        encryptedMobileNumber: {
            type: String,
            required: true
        },
        caseId: {
            type: String,
            required: true
        },
        contactType: {
            type: String,
            default: undefined
        },
        phoneBookId: {
            type: [String, Number],
            default: undefined
        },
        canUnMask: {
            type: Boolean,
            default: true
        },
    },
    data() {
        return {
            visible: false,
            unMaskedMobile: ''
        };
    },
    computed: {
        mobile() {
            return this.visible && this.unMaskedMobile ? this.unMaskedMobile : this.maskedMobile;
        }
    },
    methods: {
        onVisibleChange() {
            if (this.visible) {
                this.visible = false;

                return;
            }

            const {unmaskNavigate, caseId, encryptedMobileNumber, contactType, phoneBookId} = this;

            UnMaskResource.getUnmaskMobile({
                unmaskNavigate,
                caseId,
                encryptedMobileNumber,
                contactType,
                phoneBookId
            }).then(({data: {body}}) => {
                this.unMaskedMobile = body;
                this.visible = true;
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.unmask-part-mobile {
    display: inline-flex;
    align-items: center;
}
</style>
