import SipStat from './stat-sip';
const levelColors = [
    '#22c55e',
    '#eab308',
    '#ef4444'
];
const getQualityLevel = (source) => {
    if (source >= 80)
        return 0;
    if (source < 80 && source >= 50)
        return 1;
    return 2;
};
const getPercent = (value) => (value || value === 0) ? (+(value * 100).toFixed(3) + '%') : '';
const getRTT = (value) => value
    ? (value === -1 ? '9999 ms' : `${value} ms`)
    : '';
const qualityFormat = (quality) => {
    const { score, sl, sr, rl, rr, } = quality;
    const level = getQualityLevel(score);
    const color = levelColors[level];
    return {
        level,
        color,
        score: `${score}`,
        sipLostRate: getPercent(sl),
        sipRTT: getRTT(sr),
        rtpLostRate: getPercent(rl),
        rtpRTT: getRTT(rr),
    };
};
class StatManage {
    constructor(options) {
        this.handleRTTFinish = () => {
            const { statList } = this;
            const { minSipVisibleWindow, sipMaxAdaptRTT, sipAdaptRate, sipMaxRTT, sipPacketLossTime, sipRTTWeight, sipPacketLossWeight, minRtpVisibleWindow, rtpMaxRTT, rtpRTTWeight, rtpPacketLossWeight, rtpWeight, sipWeight, } = this.options.getConfig();
            const sipList = statList.map(({ sip }) => sip);
            const rtpList = statList.reduce((accu, curr) => [...accu, ...(curr.rtps || [])], []);
            const quality = {};
            if (!sipList.length)
                return;
            if (sipList.length < minSipVisibleWindow)
                return;
            if (this.rtpStat && rtpList.length < minRtpVisibleWindow)
                return;
            // sip 维度的计算
            const rate = (sipRTTWeight + sipPacketLossWeight) / 100;
            const lossWeight = +(sipPacketLossWeight / rate).toFixed(3);
            const rttWeight = +(sipRTTWeight / rate).toFixed(3);
            const lossRTTList = sipList.filter(sip => sip.mill && sip.mill > sipPacketLossTime);
            const validRTTList = sipList.filter(sip => sip.mill && sip.mill <= sipPacketLossTime);
            const sipRTT = validRTTList.length
                ? +(validRTTList.reduce((accu, curr) => accu + Math.min(curr.mill || sipMaxRTT, sipMaxRTT), 0)
                    / validRTTList.length).toFixed(3)
                : 9999;
            const calcSipRTT = sipRTT <= sipMaxAdaptRTT ? sipRTT / sipAdaptRate : sipRTT;
            const calcSipRTTRate = calcSipRTT !== 9999 ? +(calcSipRTT / sipMaxRTT).toFixed(3) : 1;
            const sipLossRate = +(lossRTTList.length / statList.length).toFixed(3);
            const sipScore = +((1 - sipLossRate) * lossWeight + (1 - calcSipRTTRate) * rttWeight).toFixed(3);
            quality.sl = +sipLossRate.toFixed(3);
            quality.sr = +sipRTT.toFixed(3);
            quality.srr = +calcSipRTTRate.toFixed(3);
            // 有通话
            if (rtpList.length) {
                const rrate = (rtpRTTWeight + rtpPacketLossWeight) / 100;
                const rlossWeight = +(rtpPacketLossWeight / rrate).toFixed(3);
                const rrttWeight = +(rtpRTTWeight / rrate).toFixed(3);
                const rtpPacketsLost = rtpList.reduce((accu, curr) => accu + curr.packetsLost, 0);
                const rtpPacketsSent = rtpList.reduce((accu, curr) => accu + curr.packetsSent, 0);
                const rtpLossRate = rtpPacketsSent ? rtpPacketsLost / rtpPacketsSent : 0;
                const totalRtpRTT = rtpList.reduce((accu, curr) => accu + Math.min(rtpMaxRTT, curr.rtt), 0);
                const rtpRTT = totalRtpRTT / rtpList.length;
                const rtpRTTRate = rtpRTT / rtpMaxRTT;
                quality.rl = +rtpLossRate.toFixed(3);
                quality.rr = +rtpRTT.toFixed(3);
                quality.rrr = +rtpRTTRate.toFixed(3);
                quality.score = sipScore;
                const totalWeight = rtpWeight + sipWeight;
                const rtpRate = +(rtpWeight / totalWeight).toFixed(3);
                const sipRate = +(sipWeight / totalWeight).toFixed(3);
                const rtpScore = +((1 - rtpLossRate) * rlossWeight + (1 - rtpRTTRate) * rrttWeight).toFixed(3);
                quality.score = (sipScore * sipRate + rtpScore * rtpRate).toFixed(3);
            }
            else {
                quality.score = sipScore;
            }
            this.quality = quality;
            this.options.onRTTChange({
                quality,
                formatQuality: qualityFormat(quality),
            });
        };
        this.detect = () => {
            const { device } = this.options;
            const config = this.options.getConfig();
            const { sipWindow: limit, sipPacketLossTime } = config;
            const lastStat = this.statList[this.statList.length - 1];
            if (lastStat)
                lastStat.sip.destroy();
            if (this.statList.length === limit) {
                const stat = this.statList.shift();
                if (stat) {
                    stat.sip.destroy();
                }
            }
            const sip = new SipStat({
                device,
                packetLossTime: sipPacketLossTime,
                quality: this.quality,
                onFinish: this.handleRTTFinish,
            });
            this.statList.push({ sip });
        };
        this.rtpDetect = (rtp) => {
            const stat = this.statList[this.statList.length - 1];
            if (!stat)
                return;
            stat.rtps = stat.rtps || [];
            stat.rtps.push(rtp);
        };
        this.clearStat = () => {
            this.statList = [];
        };
        this.startRTP = () => {
            this.rtpStat = true;
        };
        this.endRTP = () => {
            this.rtpStat = false;
        };
        this.statList = [];
        this.rtpStat = false;
        this.options = options;
    }
}
export default StatManage;
