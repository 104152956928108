
/**
 * @author giligili
 * @date 2021-08-18
 * @email zhimingwang@yangqianguan.com
 * @desc 预测外呼
 */

import EnumAll from 'collection-admin-web/common/constant/enum';
import Ytalk from 'collection-admin-web/common/resource/call/ytalk';
import {isDef} from 'collection-admin-web/common/util/object';

import BasicType from './basic-type';

const {YQG} = EnumAll.Call.Channel.TYPE;
const BooleanType = EnumAll.Basic.Boolean.TYPE;
const CallDialType = EnumAll.Call.DialType.TYPE;

export default class PredictType extends BasicType {

    type = 'PREDICT';

    constructor(props) {
        super(props);

        this.title = '【预测进线】';
        this.extraData.dialType = CallDialType.NORMAL;
    }

    /* @desc override */
    getCaseDetail() {
        const {title, callDirection} = this;
        const {
            callId,
            predictiveCustomData
        } = this.sharedData;
        const {gatewayCode} = this.inviteInfo;

        const payload = {
            customData: predictiveCustomData,
            thirdPartyType: YQG,
            gatewayCode
        };

        return Ytalk.beforeConnect(payload)
            .then(({data: {body}}) => {
                if (!body) return {};

                const {sharedDataRet} = this;
                const {
                    callInManualAssign,
                    caseAssigned,
                    caseId,
                    caseType,
                    collectionType,
                    contactVerifyStatus = null,
                    forceNoAssign = BooleanType.FALSE,
                    message,
                    maskedMobileNumber,
                    name,
                    encryptedUserName,
                    phoneBookId = null,
                    relationship = null,
                    sdkType,
                    encryptedMobileNumber,

                    isNewRulesForManualAssign,
                    manualAssignCaseMinDurationSeconds,

                    gatewayName,

                    // useNewAutoDialStrategy值为TRUE走预测外呼新逻辑， https://www.tapd.cn/30882228/prong/stories/view/1130882228001094778
                    useNewAutoDialStrategy
                } = body;

                const modalInfo = {sdkType, message, caseId};
                /*
                 * isNewRulesForManualAssign有2个类型-_-
                 * 1. undefind表示是否启用ABTest T59141
                 * 2. BooleanType表示当前是实验组案件还是对照组案件
                 * */
                const isPredictABTest = isDef(isNewRulesForManualAssign);

                const contactInfo = {
                    caseId,
                    caseType,
                    collectionType,
                    encryptedCollecteeMobile: encryptedMobileNumber,
                    encryptedMobileNumber,
                    maskedMobileNumber,
                    phoneBookId,
                    contactType: collectionType,
                    displayMobileNumber: maskedMobileNumber,
                    encryptMobileNumber: encryptedMobileNumber,
                    customData: predictiveCustomData,
                    thirdPartyType: YQG,
                    callInManualAssign,
                    contactVerifyStatus,
                    forceNoAssign,
                    callDirection,
                    name,
                    encryptedUserName,
                    relationship,

                    callId,
                    isPredictABTest,
                    isNewRulesForManualAssign,
                    manualAssignCaseMinDurationSeconds,

                    useNewAutoDialStrategy
                };

                this.extraData.contactInfo = contactInfo;
                this.extraData.encryptedMobileNumber = encryptedMobileNumber;

                sharedDataRet.resolve({
                    incomingEncryptedMobileNumber: encryptedMobileNumber,
                    maskedMobileNumber
                });

                if (
                    caseId
                    && (
                        caseAssigned === BooleanType.TRUE
                        || callInManualAssign === BooleanType.TRUE
                    )
                ) {
                    return {
                        link: true,
                        title,
                        caseId,
                        message,
                        modalInfo,
                        contactInfo,
                        gatewayName
                    };
                }

                return {link: false, title, caseId, message, contactInfo, gatewayName};
            });
    }

    /* @desc override */
    getSharedData() {
        const {sharedDataRet} = this;

        return sharedDataRet.promise;
    }

}
