<!-- @Author: xymo -->
<!-- @Date: 2021-04-13 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-08-08 17:07:59 -->

<template>
    <a-layout-content class="content">
        <a-breadcrumb
            v-if="isShowBreadcrumb"
            class="mb10"
        >
            <template v-for="({name, meta: {navTitle, hideInBreadcrumb}}, idx) in $route.matched">
                <a-breadcrumb-item
                    v-if="!hideInBreadcrumb"
                    :key="name"
                >
                    <router-link :to="idx === $route.matched.length - 1 ? $route : {name}">
                        {{ navTitle }}
                    </router-link>
                </a-breadcrumb-item>
            </template>
        </a-breadcrumb>

        <template v-if="isShowContentHead">
            <h2 v-text="$route.meta.navTitle" />
            <a-divider class="yqg-divider" />
        </template>
        <Micro
            v-if="$route.meta.isMicro"
            :page-url="pageUrl"
            :name="$route.name"
            :wujie-props="microProps"
        />
        <router-view v-else />
    </a-layout-content>
</template>

<script type="text/babel">
    import {mapGetters} from 'vuex';

    import Micro from '@shared/client/component/micro/index.vue';

    import axios from 'yqg-common/vue/vue-http';

    const urlTimestamp = Date.now();

    export default {
        name: 'Content',
        components: {
            Micro
        },
        computed: {
            ...mapGetters(['privilegeTypes']),
            microProps() {
                return {
                    router: this.$router,
                    axios,
                    permissionList: this.privilegeTypes
                };
            },
            pageUrl() {
                return this.$route.meta.pageUrl + `?t=${urlTimestamp}`;
            },
            isYchatQuoted() {
                return this.$app.isYchatQuoted;
            },
            isShowBreadcrumb() {
                return !this.isYchatQuoted && !this.$route.meta.customBreadcrumb;
            },
            isShowContentHead() {
                return !this.isYchatQuoted && !this.$route.meta.customContentHead;
            },
        },
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.content {
    background: transparent;
}
</style>
