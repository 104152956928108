/*
 * @Author: huayizhang
 * @Date: 2021-05-11 14:02:57
 * @Last Modified by:   huayizhang
 * @Last Modified time: 2021-05-11 14:02:57
 */

import Enum from '@yqg/enum';

import {BooleanType} from 'collection-admin-web/common/constant/boolean';

export {boolOf, valueOf} from '@yqg/vue/antd/util/enum';

export const isValid = str => str === 'VALID';

export const isTrue = str => str === BooleanType.TRUE;

const cacheYqgEnums = [];

export default {
    ...Enum,
    query(...vals) {
        const core = Enum.query;

        const yqgEnum = core.call(this, ...vals);

        const {queryOnce = false} = vals[1] || {};

        if (queryOnce) {
            cacheYqgEnums.push(yqgEnum);
        }

        return yqgEnum;
    },
    clearCacheYqgEnums() {
        cacheYqgEnums.forEach(yqgEnum => {
            yqgEnum.QUERY_PROMISE = null;
        });
    },
    fromPromise(promise = Promise.resolve([]), options = {}) {
        const enumRes = Enum.query(
            function () {
                return promise.then(res => {
                    return {
                        data: {
                            body: res
                        }
                    };
                });
            },
            {
                queryOnce: true,
                ...options
            }
        );
        enumRes.query();

        return enumRes;
    }
};
