/*
 * @Author: huayizhang
 * @Date: 2021-07-13 16:20:14
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-29 14:17:28
 */

import Resource from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/case/',

    listRelativeCase: {
        url: 'listRelativeCase',
        method: 'post'
    },

    // ES版本接口
    listRelativeCaseV2: {
        url: 'listRelativeCaseV2',
        method: 'post'
    },

    getRelativeCaseSummary: {
        url: 'getRelativeCaseSummary',
        method: 'post'
    },

    exportCase: {
        url: 'exportCase',
        method: 'post'
    },

    assignCase: {
        url: 'assignCase',
        method: 'post'
    },

    batchAssignCase: {
        url: 'batchAssignCase',
        method: 'post'
    },

    excelDemandLetter: {
        url: 'excelDemandLetter',
        method: 'post',
        formData: true
    },

    importCaseModelScore: {
        url: 'importCaseModelScore',
        method: 'post',
        formData: true
    },

    importMediateNumber: {
        url: 'importMediateNumber',
        method: 'post',
        formData: true
    },

    getDemandLetterServiceProviderList: {
        url: 'listImportableDemandLetterServiceProvider',
        method: 'get'
    },

    assignCaseToIvr: {
        url: 'assignCaseToIvr',
        method: 'post'
    },

    batchAssignCaseToIvr: {
        url: 'batchAssignCaseToIvr',
        method: 'post'
    },

    excelAssignCase: {
        url: 'excelAssignCase',
        method: 'post',
        formData: true
    },

    excelAssignCaseByCaseId: {
        url: 'excelAssignCaseByCaseId',
        method: 'post',
        formData: true
    },

    listOwnCase: {
        url: 'listOwnCase',
        method: 'get'
    },

    // ES版本接口
    listOwnCaseV2: {
        url: 'listOwnCaseV2',
        method: 'get'
    },

    unmaskExport: {
        url: 'unmaskExport',
        method: 'post'
    },

    exportCaseTimes: {
        url: 'exportCaseTimes',
        method: 'post'
    },

    /* @desc 停催 */
    stopCollection: {
        url: 'stopCollection',
        method: 'post'
    },

    /* @desc 停催 */
    stopCollectionV2: {
        url: 'stopCollectionV2',
        method: 'post'
    },

    /* @desc 停催类型 */
    getStopCollectionCacheSetTypeMap: {
        url: 'getStopCollectionCacheSetTypeMap',
        method: 'get'
    },

    /* @desc 根据案件筛选条件批量发送短信 */
    batchSendSms: {
        url: 'batchSendSms',
        method: 'post'
    },

    /* @desc 超过诉讼期的订单，上传excel, 然后发短信 */
    excelBatchSendSms: {
        url: 'excelBatchSendSms',
        method: 'post',
        formData: true
    },

    /* @desc 批量开通支付宝微信还款 */
    batchOpenRepaymentSwitch: {
        url: 'batchOpenRepaymentSwitch',
        method: 'post'
    },

    /* @desc 停催短信 */
    getStopCollectionMessageGroup: {
        url: 'getStopCollectionMessageGroup',
        method: 'get'
    }
};

const resource = Resource.create(api);
const handleLastColResults = ({lastColResults, ...rest}) => (
    lastColResults
        ? JSON.stringify(lastColResults)
        : JSON.stringify((rest.unconnectResults || []).concat(rest.connectResults || []).concat(rest.ivrResults || []))
);

export default {
    ...resource,

    listRelativeCase: ({lastColResults, ...rest}) => resource.listRelativeCase({
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }).then(({data: {body: {dataJson}}}) => {
        try {
            const body = JSON.parse(dataJson);

            return {data: {body}};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    listRelativeCaseV2: ({lastColResults, ...rest}) => resource.listRelativeCaseV2({
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }).then(({data: {body}, config}) => {
        try {
            return {data: {body}, config};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    getRelativeCaseSummary: ({lastColResults, ...rest}) => resource.getRelativeCaseSummary({
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }),

    exportCase: ({tags, notExistsTags, lastColResults, ...rest}, ...other) => resource.exportCase({
        ...rest,
        tagStr: tags && JSON.stringify(tags),
        notExistsTagStr: notExistsTags && JSON.stringify(notExistsTags),
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }, ...other),

    unmaskExport: ({tags, notExistsTags, lastColResults, ...rest}) => resource.unmaskExport({
        ...rest,
        tagStr: tags && JSON.stringify(tags),
        notExistsTagStr: notExistsTags && JSON.stringify(notExistsTags),
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }),

    assignCase: ({caseIds, ...rest}) => resource.assignCase({
        ...rest,
        caseIds: JSON.stringify(caseIds)
    }),

    batchAssignCase: ({lastColResults, assignmentMap, ...rest}) => resource.batchAssignCase({
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest}),
        assignmentMap: JSON.stringify(assignmentMap)
    }),

    assignCaseToIvr: ({caseIds, ...rest}) => resource.assignCaseToIvr({
        ...rest,
        caseIds: JSON.stringify(caseIds)
    }),

    batchAssignCaseToIvr: ({lastColResults, ...rest}) => resource.batchAssignCaseToIvr({
        ...rest,
        lastColResults: JSON.stringify(lastColResults)
    }),

    listOwnCase: ({params: {lastColResults, ...rest}}) => resource.listOwnCase({params: {
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }}).then(({data: {body: {dataJson}}, ...rest}) => {
        try {
            const {
                dataList = [],
                totalCount
            } = JSON.parse(dataJson);

            return {data: {body: {
                // timeLastCollected为0时filter出错
                dataList: dataList.map(({timeLastCollected, ...others}) => ({
                    ...others,
                    timeLastCollected: timeLastCollected || null
                })),
                totalCount
            }}, ...rest};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    listOwnCaseV2: ({params: {lastColResults, ...rest}}) => resource.listOwnCaseV2({params: {
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    }}).then(({data: {body: {caseList = [], totalCount}}, ...rest}) => {
        try {
            return {data: {body: {
                dataList: caseList,
                totalCount
            }}, ...rest};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    batchSendSms: ({lastColResults, ...rest}) => resource.batchSendSms({
        ...rest,
        lastColResults: handleLastColResults({lastColResults, ...rest})
    })
};
