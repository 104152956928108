/**
 * @Author: giligili
 * @Date: 2021-05-13
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-23 18:34:46
 */

export const AccountApplyDetail = 'AccountApplyDetail'; // 账号申请审批详情

export const AccountManager = 'AccountManager'; // 账号管理

export const PermissionPrivilegeGroupManage = 'PermissionPrivilegeGroupManage'; // 权限组管理
export const PermissionPrivilegePointManage = 'PermissionPrivilegePointManage'; // 权限点管理

export const PermissionInstitutionArea = 'PermissionInstitutionArea'; // 机构管理
export const PermissionInstitutionGroup = 'PermissionInstitutionGroup'; // 机构树形图

export const QualityReviewListFail = 'QualityReviewListFail';
export const QualityReviewListWarn = 'QualityReviewListWarn';
export const QualityReviewListCode = 'QualityReviewListCode';

export const CaseDetail = 'CaseDetail'; // 案件详情
export const RemindCaseDetail = 'RemindCaseDetail'; // 催告案件详情
export const CaseRemind = 'CaseRemind'; // 委案提醒
export const CaseLawsuit = 'CaseLawsuit'; // 诉讼案件列表
export const CaseLawsuitDetail = 'CaseLawsuitDetail'; // 诉讼案件详情

export const QualityDetail = 'qualityDetail'; // 质检详情

export const CallRecord = 'CallRecord'; // 录音查询

export const QualityReviewFlow = 'QualityReviewFlow'; // 复议流
export const QualityReviewDetail = 'QualityReviewDetail'; // 复议详情

export const CaseOwnAssistStart = 'CaseOwnAssistStart'; // 我发起的协催
export const CaseOwnAssistReceived = 'CaseOwnAssistReceived'; // 我收到的协催

export const MyFeedback = 'MyFeedback'; // 我的反馈

export const AuditMonitor = 'AuditMonitor'; // 稽核工作量监控

export const ComplaintMonitor = 'ComplaintMonitor'; // 投诉工作量监控

export const CaseAppLoginHistory = 'CaseAppLoginHistory'; // APP登录历史

export const RepairQuailtyDetail = 'RepairQuailtyDetail'; // 录音质检详情

export const ComplaintQualityDetail = 'ComplaintQualityDetail'; // 投诉质检详情

export const FeedbackRecord = 'FeedbackRecord'; // 反馈工单记录

export const WorkStatusNotification = 'WorkStatusNotification'; // 工作状态提醒

export const CaseRelative = 'caseRelative'; // 辖内委案
export const CaseRelativeNew = 'caseRelativeNew'; // 辖内委案(新)
export const CaseRelativeRemind = 'caseRelativeRemind'; // 辖内催告委案

export const AssignSchedule = 'AssignSchedule'; // 分案排班
export const WorkTime = 'WorkTime'; // 工作时间

export const Cockpit = 'Cockpit'; // 驾驶舱

export const RuleCodeRecord = 'RuleCodeRecord'; // 规则码分案记录
export const RuleCodeRelation = 'RuleCodeRelation'; // 规则码案件互查（当日）

export const StopCollection = 'stopCollection'; // 停催管理
