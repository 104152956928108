class SessionQueue {
    constructor() {
        this.sessions = [];
    }
    push(session) {
        if (this.sessions.length === 5) {
            const session = this.sessions.shift();
            if (!session)
                return;
            session.destroy(true);
        }
        this.sessions.push(session);
    }
    pop(session) {
        this.sessions = this.sessions.filter(sess => sess !== session);
    }
    getLast() {
        return this.sessions[this.sessions.length - 1];
    }
    clear() {
        this.sessions.forEach(session => {
            session.destroy();
        });
        this.sessions = [];
    }
}
export default SessionQueue;
