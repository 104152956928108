var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isShow
    ? _c(
        "span",
        { staticClass: "yqg-router-link" },
        [
          _vm.canLink
            ? [
                !_vm.hash
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            query: _vm.query,
                            params: _vm.params,
                            name: _vm.toRouterName,
                          },
                          rel: "opener",
                          target: _vm.target,
                        },
                      },
                      [
                        _vm._t("default", function () {
                          return [_vm._v(_vm._s(_vm.text))]
                        }),
                      ],
                      2
                    )
                  : _c(
                      "a",
                      { on: { click: _vm.routerLink } },
                      [
                        _vm._t("default", function () {
                          return [_vm._v(_vm._s(_vm.text))]
                        }),
                      ],
                      2
                    ),
              ]
            : _c(
                "span",
                [
                  _vm._t("default", function () {
                    return [_vm._v(_vm._s(_vm.text))]
                  }),
                ],
                2
              ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }