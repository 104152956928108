var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "yqg-modal",
    _vm._g(
      {
        staticClass: "user-info-modal",
        class: _vm.customClass,
        attrs: { title: "用户信息详情" },
        scopedSlots: _vm._u([
          {
            key: "container",
            fn: function () {
              return [
                _vm.userInfo
                  ? _c(
                      "div",
                      [
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    姓名\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.userName) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    身份证号\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("idNumberMask")(
                                      _vm.userInfo.idInfo.idNo
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    年龄\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.age) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    民族\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.userInfo.idInfo.nation) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    地址\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.userInfo.idInfo.address) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.$app.permissions
                          .EXTRA_DISPLAY_USER_ADDRESS_INSTITUTION
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    发证机关\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.userInfo.idInfo.issueAgency) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    有效期\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._f("moment")(
                                      _vm.userInfo.idInfo.expireDate,
                                      "YYYY年MM月DD日"
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.userInfo.loanBankAccount
                          ? [
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 6 } }, [
                                    _vm._v(
                                      "\n                        鉴权储蓄卡号\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("a-col", { attrs: { span: 18 } }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.userInfo.loanBankAccount
                                            .bankAccountNumber
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 6 } }, [
                                    _vm._v(
                                      "\n                        银行名称\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("a-col", { attrs: { span: 18 } }, [
                                    _vm._v(
                                      "\n                        " +
                                        _vm._s(
                                          _vm.userInfo.loanBankAccount.bankName
                                        ) +
                                        "\n                    "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a-row",
                                [
                                  _c("a-col", { attrs: { span: 6 } }, [
                                    _vm._v(
                                      "\n                        银行预留手机号\n                    "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 18 } },
                                    [
                                      _c("yqg-unmask-part-mobile", {
                                        attrs: {
                                          "masked-mobile":
                                            _vm.userInfo.loanBankAccount
                                              .mobileNumber,
                                          "case-id": _vm.caseId,
                                          "unmask-navigate": _vm.unmaskNavigate,
                                          "encrypted-mobile-number":
                                            _vm.userInfo.loanBankAccount
                                              .encryptedMobileNumber,
                                        },
                                      }),
                                      _vm._v(" "),
                                      !_vm.userInfo.loanBankAccount
                                        .equalToSelfMobile
                                        ? _c("yqg-layout-dial-btn", {
                                            attrs: {
                                              "case-id": _vm.caseId,
                                              "encrypted-mobile-number":
                                                _vm.userInfo.loanBankAccount
                                                  .encryptedMobileNumber,
                                              "contact-info": _vm.contactInfo,
                                              "relative-type": _vm.relativeType,
                                              "call-tag": _vm.callTag,
                                              source: _vm.dialSource,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    注册手机号\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.mobileNumber) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.riskBaseInfo
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    电子邮件\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("emailMask")(
                                          _vm.riskBaseInfo.email
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.$app.permissions
                          .EXTRA_DISPLAY_USER_ADDRESS_INSTITUTION
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    省-市-区\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.userInfo.allContactInfo
                                          .addressProvince
                                      ) +
                                      "\n                    -\n                    " +
                                      _vm._s(
                                        _vm.userInfo.allContactInfo.addressCity
                                      ) +
                                      "\n                    -\n                    " +
                                      _vm._s(
                                        _vm.userInfo.allContactInfo
                                          .addressDistrict
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    详细地址\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.userInfo.allContactInfo.addressDetail
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.riskBaseInfo
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    学历\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.riskBaseInfo.education || "/"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    婚姻状态\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.$options.MarryStatusMap[
                                      _vm.userInfo.allContactInfo.marryStatus
                                    ]
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.riskBaseInfo
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    就业状况\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.riskBaseInfo.workStatus || "/"
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    公司名称\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.userInfo.cashLoanEmploymentInfo
                                      .companyName
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    月收入（元）\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.userInfo.cashLoanEmploymentInfo
                                      .monthlyIncome
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    职业\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm.userInfo.cashLoanEmploymentInfo
                                      .occupation
                                  ) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a-row",
                          [
                            _c("a-col", { attrs: { span: 6 } }, [
                              _vm._v(
                                "\n                    工资发放日\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("a-col", { attrs: { span: 18 } }, [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.formatPayDay(_vm.userInfo)) +
                                  "\n                "
                              ),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.userInfo.companyAddress
                          ? _c(
                              "a-row",
                              [
                                _c("a-col", { attrs: { span: 6 } }, [
                                  _vm._v(
                                    "\n                    公司地址\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("a-col", { attrs: { span: 18 } }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.userInfo.companyAddress) +
                                      "\n                "
                                  ),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.btnConfig
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }