/*
 * @Author: yefenghan 
 * @Date: 2024-10-30 16:23:54 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-11-01 16:24:30
 */

import {create} from '@yqg/resource';

const api = {
    urlPrefix: '/admin/operation/col/tag/',

    getAvailableMobileTagConfigs: {
        url: 'getAvailableMobileTagConfigs',
        method: 'get'
    },

    createMobileTag: {
        url: 'createMobileTag',
        method: 'post'
    },

    deleteMobileTag: {
        url: 'deleteMobileTag',
        method: 'post'
    },
};

export default create(api);
