var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "ytalk-wifi",
      style: {
        width: _vm.getWidth(_vm.level),
        height: parseInt(_vm.getWidth(_vm.level)) / 2 + "px",
      },
    },
    _vm._l(_vm.level, function (item) {
      return _c("div", {
        key: item,
        staticClass: "arc",
        class: "arc-" + item,
        style: _vm.getStyle(item),
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }