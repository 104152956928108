<!-- @Author: yefenghan -->
<!-- @Date: 2024-7-12 12:08:25 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-07-12 12:10:22 -->
<template>
    <div class="work-status-warn-duration">
        【工作状态异常提醒】
        <div class="mt10">
            <p
                v-for="item of descriptionList"
                :key="item.label"
                :label="item.label"
            >
                {{ item.label }}: {{ item.value }}
            </p>
        </div>
        <div class="control">
            <a @click="finishNotification(notification.data)">我知道了</a>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex';

import {transferMilliseconds} from 'collection-admin-web/common/util/time';

export default {
    name: 'WorkStatusWarnDuration',

    props: {
        notification: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        descriptionList() {
            const {extraData} = this.notification.data;

            return [
                {
                    label: '催收员姓名',
                    value: extraData.collectorName
                },
                {
                    label: '异常状态',
                    value: extraData.workStatus
                },
                {
                    label: '持续时间',
                    value: transferMilliseconds(extraData.duration)
                }
            ];
        }
    },
    methods: {
        ...mapActions('notification', ['finishNotification'])
    }
};
</script>

<style lang="scss" scoped>
.work-status-warn-duration {
    position: relative;

    .control {
        position: absolute;
        right: 20px;
        bottom: 0;
    }
}
</style>
