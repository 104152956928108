var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import customizeConfigCenter from '@shared/client/util/config-center';
const jsonParse = (val, defaultValue = null) => {
    if (!val)
        return defaultValue;
    let ret = defaultValue;
    try {
        ret = JSON.parse(val);
    }
    catch (err) {
        // ignore err;
    }
    return ret;
};
const getConfig = () => __awaiter(void 0, void 0, void 0, function* () {
    const config = yield customizeConfigCenter({ app: 'web-ytalk-sdk', prefix: 'ytalk' }, {})();
    const finallConfig = config || {};
    finallConfig.networkQuality = jsonParse(finallConfig.networkQuality, {});
    return finallConfig;
});
export default getConfig;
