<template>
    <div
        class="ytalk-wifi"
        :style="{
            width: getWidth(level),
            height: parseInt(getWidth(level)) / 2 + 'px',
        }"
    >
        <div
            v-for="item in level"
            :key="item"
            class="arc"
            :class="`arc-${item}`"
            :style="getStyle(item)"
        />
    </div>
</template>

<script type="text/babel">

    const colors = ['#22c55e', '#eab308', '#ef4444'];

    const radius = 3;
    const gap = 12;

    export default {
        name: 'CallCenterWifi',

        props: {
            level: {
                type: Number,
                default: 3,
            },

            signal: {
                type: Number,
                default: 0,
            }
        },

        methods: {
            getStyle(id) {
                const {signal, level} = this;

                const width = this.getWidth(id);

                const key = id === 1 ? 'borderColor' : 'borderTopColor';
                const color = id > (level - signal) ? '#94a3b8' : colors[signal];

                return {
                    width,
                    height: width,
                    border: `${radius}px solid transparent`,
                    [key]: color,
                    marginTop: parseInt(this.getWidth(level)) / 2 - radius + 'px',
                };
            },

            getWidth(id) {
                return (id - 1) * gap + (id === 1 ? 0 : 6) + 'px';
            }
        }
    };
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .ytalk-wifi {
        position: relative;

        .arc {
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            background-color: transparent;
        }
    }
</style>
