/*
 * @Author: xingpan
 * @Date: 2024-03-14 10:28:27
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-10-28 16:31:48
 */
import * as Sentry from '@sentry/vue';
export const apiErrorReportToSentry = function (request, response) {
    try {
        const { url, method, params, data, deviceToken, cookies } = request || {};
        const { httpCode, code, traceId, detail } = response || {};
        const isFileUploadApi = data instanceof FormData;
        Sentry.withScope(scope => {
            var _a, _b;
            scope.setLevel('info');
            scope.setTags({
                httpCode,
                isFileUploadApi,
                requestUrl: url,
                requestMethod: method,
                responseCode: code,
                deviceToken,
                responseTraceId: traceId,
                responseMessage: detail
            });
            scope.setExtra('hasUserToken', cookies === null || cookies === void 0 ? void 0 : cookies.includes('userToken'));
            const ckList = ((_a = cookies === null || cookies === void 0 ? void 0 : cookies.split) === null || _a === void 0 ? void 0 : _a.call(cookies, ';')) || [];
            scope.setExtra('ckCount', ckList === null || ckList === void 0 ? void 0 : ckList.length);
            scope.setExtra('ckSize', (_b = ckList === null || ckList === void 0 ? void 0 : ckList.map) === null || _b === void 0 ? void 0 : _b.call(ckList, (ck) => ck === null || ck === void 0 ? void 0 : ck.length));
            scope.setContext('requestParams', { params, data });
            // 在 Error 对象的消息中加入动态内容 防止重复url不上报
            const uniqueUrl = `${url}-${new Date().getTime()}`;
            Sentry.captureException(new Error(uniqueUrl));
        });
    }
    catch (error) {
        // ignore
    }
};
