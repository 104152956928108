import { jsonParse } from '../helper';
const KEY = '_YTALK_CONFIG_';
class HubService {
    constructor({ config }) {
        this.handleStorageChange = (event) => {
            if (event.key !== KEY)
                return;
            const config = jsonParse(event.newValue, {});
            this.config = config;
        };
        this.init = () => {
            window.addEventListener('storage', this.handleStorageChange);
        };
        this.readConf = () => {
            return this.config;
        };
        this.writeConf = (config) => {
            this.config = config || {};
            window.localStorage.setItem(KEY, JSON.stringify(this.config));
        };
        this.config = {};
        this.clientMap = {};
        this.getClientCallbacks = [];
        this.writeConf(config);
        this.init();
    }
    register(name, client) {
        this.clientMap[name] = client;
        this.getClientCallbacks.forEach(({ name: clientName, callback }) => {
            if (clientName !== name)
                return;
            this.clientResponse(clientName, callback);
        });
    }
    unRegister(name) {
        delete this.clientMap[name];
    }
    clientResponse(name, callback) {
        const client = this.clientMap[name];
        if (!client)
            return false;
        callback(client);
        return true;
    }
    getClient(name, callback) {
        if (this.clientResponse(name, callback))
            return;
        this.getClientCallbacks.push({
            name,
            callback,
        });
    }
}
export default HubService;
