class SipStat {
    constructor(options) {
        this.send = () => {
            const { device } = this;
            const target = device.configuration.uri;
            const body = this.quality || {};
            const options = { extraHeaders: ['Network_Quality_Check: true'] };
            this.message = device.sendMessage(target, JSON.stringify(body), options);
            const transactions = device._transactions['nict']; // eslint-disable-line
            Object.values(transactions).forEach((transaction) => {
                this.addTransaction(transaction);
            });
            setTimeout(() => {
                if (this.status !== 'ING')
                    return;
                this.status = 'LOSS';
                const time = new Date().getTime() - this.timestamp;
                this.mill = time;
                this.options.onFinish();
                return;
            }, this.options.packetLossTime);
        };
        this.listenTransaction = () => {
            const { transaction } = this;
            if (!transaction)
                return;
            this.handleStateChange = () => {
                if (transaction.state !== 5)
                    return;
                if (this.status !== 'ING')
                    return;
                const time = new Date().getTime() - this.timestamp;
                this.status = 'END';
                this.mill = time;
                this.options.onFinish();
            };
            transaction.addListener('stateChanged', this.handleStateChange);
        };
        this.removeTransaction = () => {
            const { transaction } = this;
            if (!transaction || !this.handleStateChange)
                return;
            transaction.removeListener('stateChanged', this.handleStateChange);
        };
        this.checkTransaction = (transaction) => {
            if (!this.message)
                return;
            if (transaction.request !== this.message._request)
                return; // eslint-disable-line
            this.transaction = transaction;
            this.listenTransaction();
        };
        this.addTransaction = (transaction) => {
            var _a;
            const { type, request } = transaction;
            if (type !== 'nict' || request.method !== 'MESSAGE')
                return;
            if (!((_a = request.extraHeaders) === null || _a === void 0 ? void 0 : _a.some((extraHeader) => /Network_Quality_Check/.test(extraHeader))))
                return;
            this.checkTransaction(transaction);
        };
        this.destroy = () => {
            this.removeTransaction();
            if (this.status === 'ING') {
                this.status = 'DESTROY';
            }
        };
        this.options = options;
        this.status = 'ING';
        this.device = options.device;
        this.quality = options.quality;
        this.timestamp = new Date().getTime();
        this.send();
    }
}
export default SipStat;
