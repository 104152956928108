/*
 * @Author: yefenghan 
 * @Date: 2024-08-20 15:01:53 
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-25 19:02:01
 */

import EnumAll from 'collection-admin-web/common/constant/enum';

export default (phone, contactType) => {
    const ContactType = EnumAll.Call.ContactType.TYPE;

    if (!phone) {
        return null;
    }

    const phoneStr = String(phone);

    // 本人手机号全隐藏
    if (contactType === ContactType.NEW_SELF || contactType === ContactType.REPAIRED_SELF || contactType === ContactType.SELF) {
        return `${'*'.repeat(phoneStr.length)}`;
    }

    if (phoneStr.length <= 2) {
        return phoneStr;
    }

    return `${'*'.repeat(phoneStr.length - 2)}${phoneStr.slice(-2)}`;
};
