<!-- @Author: yefenghan -->
<!-- @Date: 2024-7-12 15:45:15 -->
<!-- @Last Modified by: yefenghan -->
<!-- @Last Modified time: 2024-07-12 15:45:42 -->
<template>
    <div class="ytalk-status mb10">
        <span :class="descClass">当前坐席状态: </span>
        <a-spin
            class="spin"
            :spinning="isLoading"
        >
            <a-select
                style="width: 100%;"
                :value="workStatus"
                :dropdown-menu-style="{maxHeight: '218px'}"
                :options="workStatusMap"
                :get-popup-container="getPopupContainer"
                @change="onWorkStatusChange"
            />
        </a-spin>
    </div>
</template>

<script>

export default {
    props: {
        isBusy: {
            type: Boolean,
            default: false
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        workStatusMap: {
            type: Array,
            default: () => []
        },
        workStatus: {
            type: [Number, String],
            default: ''
        }
    },
    computed: {
        descClass() {
            return this.isBusy ? 'yqg-text-warning' : 'yqg-text-success';
        }
    },
    methods: {
        onWorkStatusChange(id) {
            this.$emit('workStatusChange', id);
        },
        getPopupContainer(triggerNode) {
            return triggerNode.parentNode;
        }
    }
};
</script>

<style lang="scss" scoped>
.ytalk-status {
    display: flex;

    .spin {
        margin-left: 10px;
        flex: 1;
    }
}
</style>
