var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WujieVue", {
    attrs: {
      name: _vm.name,
      url: _vm.pageUrl,
      sync: true,
      props: _vm.wujieProps,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }