/*
 * @Author: huayizhang
 * @Date: 2021-06-29 15:57:44
 * @Last Modified by: huayizhang
 * @Last Modified time: 2021-07-07 15:25:32
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { assign, customGet, customPost } from '@yqg/resource';
import { roundTimestampToMonthStart } from 'yqg-common/vue/utils/time';
const api = {
    urlPrefix: '/admin/operation/col/callQuality/',
    listRelativeTask: customPost('listRelativeTask'),
    exportRelativeTask: customGet({ url: 'exportRelativeTask', download: true }),
    uploadRecord: customPost({ url: 'uploadRecord', formData: true }),
    assignTask: customPost('assignTask'),
    getTotalCount: customPost('getTotalCount'),
    batchAssignTask: customPost('batchAssignTask'),
    getAuditMap: customGet('getAuditMap'),
    listFailReason: customGet('listFailReason'),
    listWarnReason: customGet('listWarnReason')
};
const resource = assign(api);
const handleCond = cond => {
    var _a, _b, _c, _d;
    return (Object.assign(Object.assign({}, cond), { callTimeMonth: roundTimestampToMonthStart(cond.callTimeMonth), machineResults: JSON.stringify(cond.machineResults), manualResults: JSON.stringify(cond.manualResults), statisfactionKeys: JSON.stringify(cond.statisfactionKeys), machineFailReasons: JSON.stringify([...((_a = cond.machineFailReasons) !== null && _a !== void 0 ? _a : []), ...((_b = cond.newMachineFailReasons) !== null && _b !== void 0 ? _b : [])]), manualFailReasons: JSON.stringify([...((_c = cond.manualFailReasons) !== null && _c !== void 0 ? _c : []), ...((_d = cond.newManualFailReasons) !== null && _d !== void 0 ? _d : [])]) }));
};
export default Object.assign(Object.assign({}, resource), { listRelativeTask: data => resource.listRelativeTask(handleCond(data)), exportRelativeTask: ({ params }) => resource.exportRelativeTask({ params: handleCond(params) }), getTotalCount: data => resource.getTotalCount(handleCond(data)), batchAssignTask: (_a) => {
        var { assignmentMap } = _a, rest = __rest(_a, ["assignmentMap"]);
        return resource.batchAssignTask(Object.assign(Object.assign({}, handleCond(rest)), { assignmentMap: JSON.stringify(assignmentMap) }));
    } });
