/**
 * @Author: xymo
 * @Date: 2021-04-21
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-09-25 14:53:55
 */

import date from '@yqg/vue/antd/filter/date';

import formatDate from './date';
import emailMask from './email-mask';
import idNumberMask from './id-number-mask';
import MillisToMinSecond from './millis-to-min-second';
import NumberCommas from './number-commas';
import NumberCommasFixed2 from './number-commas-fixed2';
import phoneNumberMask from './phone-number-mask';

export default {
    install(Vue) {
        Vue.filter('moment', formatDate);
        Vue.filter('ymd', date('YYYY-MM-DD'));
        Vue.filter('hm', date('HH:mm'));
        Vue.filter('dateTime', date('YYYY-MM-DD HH:mm:ss'));
        Vue.filter('numberCommas', NumberCommas);
        Vue.filter('numberCommasFixed2', NumberCommasFixed2);
        Vue.filter('millisToMinSecond', MillisToMinSecond);
        Vue.filter('idNumberMask', idNumberMask);
        Vue.filter('emailMask', emailMask);
        Vue.filter('phoneNumberMask', phoneNumberMask);
    }
};
