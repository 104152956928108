import { assign, customGet, customPost } from '@yqg/resource';
const api = {
    urlPrefix: '/admin/operation/col/unmask/',
    /* @desc 明文导出  */
    export: customPost('export'),
    /* @desc 明文导出机构与场景列表 */
    orgAndSceneList: customGet('getOrganizationAndSceneList'),
    /* @desc 调解资料列表接口 */
    list: customGet('list'),
    /* @desc 资料申请记录列表接口 */
    listApplyRecords: customPost('listApplyRecords'),
    /* @desc 申请调解资料包 */
    applyArbitration: customPost('applyArbitration'),
    /* @desc 下载资料包 */
    applyDownload: customGet('applyDownload'),
    /** @desc 解密手机号 */
    getUnmaskMobile: customPost('getUnmaskMobile'),
};
const data = assign(api);
export default data;
