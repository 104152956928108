var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ytalk", style: _vm.ytalkZIndex },
    [
      !_vm.isLogin && _vm.prepared
        ? _c(
            "a-spin",
            { attrs: { spinning: _vm.isRegistering } },
            [
              _c("a-button", {
                attrs: { type: "primary", disabled: !_vm.ytalkConfig },
                domProps: { textContent: _vm._s("登录外呼中心") },
                on: { click: _vm.loginYtalk },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "ytalk-container" },
            [
              _c(
                "div",
                { staticClass: "network-info" },
                [
                  _vm.quality && (_vm.quality.score || _vm.quality.score === 0)
                    ? _c("NetworkInfo", { attrs: { quality: _vm.quality } })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.canCall
                ? _c(
                    "div",
                    { staticClass: "ytalk-call-prepare" },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-direction": "row-reverse",
                            "margin-bottom": "10px",
                          },
                        },
                        [
                          _c(
                            "a-button",
                            {
                              staticStyle: { color: "#339bf0" },
                              attrs: { type: "exclamation-circle" },
                              on: { click: _vm.onStartSpeechDetection },
                            },
                            [
                              _vm._v(
                                "\n                    通话检测\n                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.agentStatusChangeTipsVisible &&
                      _vm.ytalkConfig &&
                      _vm.ytalkConfig.autoChangeAgentStatusInterval
                        ? _c("agent-status-change-timer", {
                            attrs: {
                              countdown:
                                _vm.ytalkConfig.autoChangeAgentStatusInterval,
                              "agent-status": _vm.ytalkConfig.agentStatus,
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("work-status", {
                        attrs: {
                          "is-busy": _vm.isBusy,
                          "work-status": _vm.shared.workStatus,
                          "work-status-map": _vm.shared.workStatusMap,
                          "is-loading": _vm.shared.workStatusLoading,
                        },
                        on: { workStatusChange: _vm.onWorkStatusChange },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ytalk-call mb10" },
                        [
                          _c("a-input", {
                            attrs: {
                              type: "text",
                              placeholder: "请输入对方号码",
                              value: _vm.shared.mobileNumber,
                              "max-length": 32,
                              disabled: _vm.disableInputMobileNumber,
                            },
                            on: { change: _vm.onChangeMobileNumber },
                          }),
                          _vm._v(" "),
                          _c("a-button", {
                            staticClass: "yqg-btn-info",
                            domProps: { textContent: _vm._s("呼叫") },
                            on: { click: _vm.manualDial },
                          }),
                          _vm._v(" "),
                          _c("a-button", {
                            attrs: { type: "primary" },
                            domProps: { textContent: _vm._s("登出") },
                            on: {
                              click: function ($event) {
                                return _vm.handleUserAction("unregister")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isBusy
                        ? _c("span", { staticClass: "ytalk-tip" }, [
                            _vm._v(
                              "\n                当前状态无法接入电话\n            "
                            ),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm.isCallTest
                ? [
                    _c("span", { staticClass: "connect" }, [
                      _vm._v("\n                回声测试中\n            "),
                    ]),
                  ]
                : _vm.canHangUp
                ? _c(
                    "div",
                    [
                      _vm.isConnected
                        ? _c("keyboard", {
                            ref: "keyboard",
                            on: { change: _vm.sendDTMF },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ytalk-call-info" },
                        [
                          _vm.isConnected
                            ? _c(
                                "span",
                                { staticClass: "ytalk-call-connected" },
                                [
                                  _vm.shared.answerList
                                    ? _c("agent-copilot", {
                                        attrs: {
                                          "copilot-info-list":
                                            _vm.shared.answerList,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.shared.customerInfo &&
                                  _vm.shared.customerInfo.gatewayName &&
                                  _vm.$app.permissions
                                    .CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE
                                    ? _c("p", [
                                        _c("span", [_vm._v("线路")]),
                                        _vm._v(": "),
                                        _c(
                                          "span",
                                          { staticClass: "yqg-text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.shared.customerInfo
                                                  .gatewayName
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticClass: "yqg-text-success" },
                                    [
                                      _vm.shared.maskedMobileNumber
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.shared.maskedMobileNumber
                                              )
                                            ),
                                          ])
                                        : _vm._e(),
                                      _vm._v(
                                        "\n\n                        通话中\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("timer", {
                                    ref: "timer",
                                    attrs: {
                                      "start-time": _vm.shared.startTime,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "a-button",
                                    { on: { click: _vm.clickKeyboardIcon } },
                                    [
                                      _c("img", {
                                        staticClass: "icon keyboard",
                                        attrs: { src: _vm.keyboardIcon },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.shared.isMuted
                                    ? _c(
                                        "a-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUserAction(
                                                "unmute"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "icon",
                                            attrs: { src: _vm.muteIcon },
                                          }),
                                        ]
                                      )
                                    : _c(
                                        "a-button",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleUserAction(
                                                "mute"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "icon",
                                            attrs: { src: _vm.unmuteIcon },
                                          }),
                                        ]
                                      ),
                                ],
                                1
                              )
                            : _vm.canAnswer
                            ? _c(
                                "span",
                                { staticClass: "ytalk-call-receive" },
                                [
                                  _vm.shared.customerInfo &&
                                  _vm.shared.customerInfo.title
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.shared.customerInfo.title
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.shared.customerInfo &&
                                  _vm.shared.customerInfo.message
                                    ? _c("p", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.shared.customerInfo.message
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.shared.customerInfo &&
                                  _vm.shared.customerInfo.gatewayName &&
                                  _vm.$app.permissions
                                    .CASE_DETAIL_DISPLAY_CALL_IN_GATEWAY_CODE
                                    ? _c("p", [
                                        _c("span", [_vm._v("线路")]),
                                        _vm._v(": "),
                                        _c(
                                          "span",
                                          { staticClass: "yqg-text-success" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.shared.customerInfo
                                                  .gatewayName
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.shared.maskedMobileNumber
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.shared.maskedMobileNumber)
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\n                    来电呼入\n\n                    "
                                  ),
                                  _vm.shared.customerInfo &&
                                  _vm.shared.customerInfo.status === "FAIL"
                                    ? _c(
                                        "a-popconfirm",
                                        {
                                          attrs: {
                                            title:
                                              _vm.shared.customerInfo.popTitle,
                                          },
                                          on: { confirm: _vm.caseErrorAnswer },
                                        },
                                        [
                                          _c("a-button", {
                                            staticClass: "yqg-btn-success",
                                            domProps: {
                                              textContent: _vm._s("接听"),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c("a-button", {
                                        staticClass: "yqg-btn-success",
                                        domProps: {
                                          textContent: _vm._s("接听"),
                                        },
                                        on: { click: _vm.answer },
                                      }),
                                ],
                                1
                              )
                            : _c("span", { staticClass: "ytalk-call-send" }, [
                                _vm.shared.maskedMobileNumber
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.shared.maskedMobileNumber)
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  "\n\n                    呼叫中\n                "
                                ),
                              ]),
                          _vm._v(" "),
                          _c("a-button", {
                            attrs: { type: "danger" },
                            domProps: { textContent: _vm._s("挂断") },
                            on: { click: _vm.hangup },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "ytalk-audio" }, [
                _c("audio", {
                  ref: "YTalkRemoteAudio",
                  attrs: { autoplay: "autoplay" },
                }),
                _vm._v(" "),
                _c("audio", {
                  ref: "YTalkHangupRecord",
                  attrs: { src: _vm.HangupRecord },
                }),
                _vm._v(" "),
                _c("audio", {
                  ref: "YTalkRingRecord",
                  attrs: { loop: "loop", src: _vm.RingRecord },
                }),
                _vm._v(" "),
                _c("audio", {
                  ref: "YTalkCallRecord",
                  attrs: { loop: "loop", src: _vm.CallRecord },
                }),
                _vm._v(" "),
                _c("audio", {
                  ref: "YTalkConnectedRecord",
                  attrs: { src: _vm.ContentedRecord },
                }),
              ]),
              _vm._v(" "),
              _vm.detectionModalVisible
                ? _c(
                    "a-modal",
                    {
                      attrs: {
                        title: "通话检测",
                        visible: _vm.detectionModalVisible,
                        width: 600,
                        closable: false,
                        footer: null,
                      },
                    },
                    [
                      _c("SpeechDetectionModal", {
                        ref: "SpeechDetectionModal",
                        attrs: {
                          type: "collectionCallTest",
                          "call-account-id": _vm.callAccountId,
                        },
                        on: {
                          closeModal: _vm.closeDetectionModal,
                          hangup: _vm.hangup,
                          onSelectChange: _vm.onDetectionModalSelectChange,
                          setMediaConstraints: _vm.setMediaConstraints,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }