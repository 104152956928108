/*
 * @Author: xiaodongyu
 * @Date 2019-12-12 20:04:08
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-10-18 12:34:04
 */

import {memoize} from 'lodash';

import Resource from 'yqg-common/vue/resource';

const api = {
    /** @desc 获取灰度版本 */
    getReleaseVersion: {
        url: '/admin/operation/col/grey/releaseVersion',
        method: 'get'
    },

    getAutoDialStatus: {
        url: '/admin/operation/col/call/pingan/getAutoDialStatus',
        method: 'get'
    },

    getFileList: {
        url: '/admin/operation/col/getFileList',
        method: 'get'
    },

    getSomeAreaList: {
        url: '/admin/operation/col/getSomeAreaList',
        method: 'get'
    },

    // 满意度结果
    getSatisfactionMap: {
        url: '/admin/operation/col/call/getSatisfactionMap',
        method: 'get'
    },

    // 违规原因
    listFailReason: {
        url: '/admin/operation/col/callQuality/listFailReason',
        method: 'get'
    },

    // 违规原因
    getProvinceMap: {
        url: '/admin/operation/col/case/getProvinceMap',
        method: 'get'
    },

    // 催回率排名
    listCaseStatistics: {
        url: '/admin/operation/col/case/listCaseStatistics',
        method: 'get'
    },

    listCaseTagByTypeCode: {
        url: '/admin/operation/col/tag/listCaseTagByTypeCode',
        method: 'get'
    },

    // 查询明文手机号
    getUnmaskPhoneNum: {
        url: '/admin/operation/col/collector/getUnmaskPhoneNum',
        method: 'post'
    }
};

const resource = Resource.create(api);

const genFailReason = (failReasons = [], format = desc => desc) => {
    const failReasonList = Array.isArray(failReasons) ? failReasons : [failReasons];

    return failReasonList
        .map(({lvName, reasons}) => reasons.map(({key, desc}) => ({id: key, name: format(desc, lvName)})))
        .flat();
};

export default {
    ...resource,
    listFailReason: memoize(function(...vals) {
        return resource.listFailReason(...vals)
            .then(({data: {body: {failReasons = [], newListFailReason: failReasonsNew = []}}}) => {
                const body = {
                    failReasonNewAndOld: genFailReason(failReasonsNew.concat(failReasons)),
                    failReasonNew: genFailReason(failReasonsNew),
                    failReasonWithNameNew: genFailReason(failReasonsNew, (desc, lvName) => ` (${lvName})${desc}`)
                        .reduce((accu, {id, name}) => ({...accu, [id]: name}), {}),
                    failReasonLvNew: failReasonsNew,
                    failReasonLvNewAndOld: [...failReasons, ...failReasonsNew],
                    failReasonLevelReasonsListNew: failReasonsNew.map(({failLv, lvName, reasons = []}) => ({
                        failLv,
                        lvName,
                        reasonsMap: reasons.reduce((accu, {key, desc}) => ({...accu, [key]: desc}), {})
                    })),

                    // 以下是旧版质检原因
                    failReason: genFailReason(failReasons)
                        .reduce((accu, {id, name}) => ({...accu, [id]: name}), {}),
                    failReasonWithName: genFailReason(failReasons, (desc, lvName) => `${desc} (${lvName})`)
                        .reduce((accu, {id, name}) => ({...accu, [id]: name}), {}),

                    failReasonLv: failReasons,
                    failReasonLevelReasonsList: failReasons.map(({failLv, lvName, reasons = []}) => ({
                        failLv,
                        lvName,
                        reasonsMap: reasons.reduce((accu, {key, desc}) => ({...accu, [key]: desc}), {})
                    }))
                };

                return {data: {body}};
            });
    }),
};
