var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsonParse } from '../../helper';
import StatManage from './stat-manage';
const KEY = '_YTALK_NETWORK_QUALITY_';
const DEFAULT_CONFIG = {
    enable: false,
    minSipVisibleWindow: 5,
    minRtpVisibleWindow: 5,
    sipWindow: 10,
    sipMaxAdaptRTT: 100,
    sipAdaptRate: 2,
    sipMaxRTT: 160,
    sipPacketLossTime: 160,
    sipRTTWeight: 8,
    sipPacketLossWeight: 2,
    sipInterval: 2e3,
    sipWeight: 1,
    rtpInterval: 1e3,
    rtpMaxRTT: 2e3,
    rtpRTTWeight: 28,
    rtpPacketLossWeight: 9,
    rtpWeight: 2,
};
const rtpStatsTypeWhiteMap = {
    'outbound-rtp': true,
    'remote-inbound-rtp': true,
};
class NetworkQualityStat {
    constructor(options) {
        this.handleStorageChange = (event) => {
            var _a;
            if (event.key !== KEY)
                return;
            const rtt = jsonParse(event.newValue, null);
            if (!rtt)
                return;
            (_a = this.options) === null || _a === void 0 ? void 0 : _a.onRTTChange(rtt);
        };
        this.init = () => {
            window.addEventListener('storage', this.handleStorageChange);
        };
        this.onRTTChange = (rtt) => {
            var _a;
            (_a = this.options) === null || _a === void 0 ? void 0 : _a.onRTTChange(rtt);
            window.localStorage.setItem(KEY, JSON.stringify(rtt));
            if (!this.session)
                return;
            this.session.emit('NETWORK_QUALITY_CHANGE', rtt);
        };
        this.getConfig = () => {
            if (!this.hubService)
                return Object.assign({}, DEFAULT_CONFIG);
            const config = this.hubService.readConf();
            return Object.assign({}, DEFAULT_CONFIG, (config === null || config === void 0 ? void 0 : config.networkQuality) || {});
        };
        this.apply = (options) => {
            const { hubService } = options;
            this.hubService = hubService;
            // this.sipRTTClient = new SipRTTHubClient({hubService});
            hubService.getClient('registerClient', (client) => {
                this.register = client.getClient();
                this.listenRegister();
            });
            hubService.getClient('sessionClient', (client) => {
                this.session = client.getClient();
                this.listenSession();
            });
            hubService.getClient('deviceClient', (client) => {
                this.device = client.device;
                this.statManager = new StatManage({
                    device: this.device,
                    getConfig: this.getConfig,
                    onRTTChange: this.onRTTChange,
                });
            });
        };
        this.sendMessage = () => {
            this.end();
            const { device, statManager } = this;
            if (!device || !statManager)
                return this.next();
            statManager.detect();
            this.next();
        };
        this.start = () => {
            var _a, _b;
            this.onRTTChange({});
            if (!this.getConfig().enable)
                return (_b = (_a = this.statManager) === null || _a === void 0 ? void 0 : _a.clearStat) === null || _b === void 0 ? void 0 : _b.call(_a);
            this.sendMessage();
        };
        this.next = () => {
            var _a, _b;
            const config = this.getConfig();
            if (!config.enable) {
                this.onRTTChange({});
                (_b = (_a = this.statManager) === null || _a === void 0 ? void 0 : _a.clearStat) === null || _b === void 0 ? void 0 : _b.call(_a);
                return;
            }
            this.timeout = setTimeout(this.sendMessage, config.sipInterval);
        };
        this.end = () => {
            if (!this.timeout)
                return;
            clearTimeout(this.timeout);
        };
        this.listenRegister = () => {
            const { register } = this;
            if (!register)
                return;
            register.addListener('REGISTER_SUCCESSED', this.start);
            register.addListener('UN_REGISTER_SUCCESSED', this.end);
            register.addListener('REGISTER_DISTROY', this.end);
        };
        this.removeRegister = () => {
            const { register } = this;
            if (!register)
                return;
            register.removeListener('REGISTER_SUCCESSED', this.start);
            register.removeListener('UN_REGISTER_SUCCESSED', this.end);
            register.removeListener('REGISTER_DISTROY', this.end);
        };
        this.stopRTPCollection = () => {
            if (!this.rtpTimeout)
                return;
            clearTimeout(this.rtpTimeout);
        };
        this.nextRTPCollection = () => {
            const config = this.getConfig();
            if (!config.enable)
                return;
            this.rtpTimeout = setTimeout(this.rtpCollection, config.rtpInterval);
        };
        this.rtpCollection = () => __awaiter(this, void 0, void 0, function* () {
            const { peerconnection } = this;
            if (!peerconnection)
                return;
            try {
                const stats = [...(yield peerconnection.getStats()).values()]
                    .filter(({ type }) => rtpStatsTypeWhiteMap[type])
                    .reduce((accu, curr) => (Object.assign(Object.assign({}, accu), { [curr.type]: curr })), {});
                if (Object.keys(rtpStatsTypeWhiteMap).some(key => !stats[key]))
                    return this.nextRTPCollection();
                const remoteInboundRtp = stats['remote-inbound-rtp'] || {};
                const outboundRtp = stats['outbound-rtp'] || {};
                const totalPacketsSent = outboundRtp.packetsSent || 0; // 总的发送的包的数量
                const totalPacketsLost = remoteInboundRtp.packetsLost || 0; // 总的丢包数
                const totalRTTCount = remoteInboundRtp.roundTripTimeMeasurements; // 总的 RTT 次数
                const totalRTT = remoteInboundRtp.totalRoundTripTime * 1000; // 总的 RTT 时间
                const lastRTT = remoteInboundRtp.roundTripTime * 1000; // 最近一次的 rtt 时间
                this.nextRTPCollection();
                if (!totalPacketsSent)
                    return;
                const { totalPacketsSent: prevPacketsSent, totalPacketsLost: prevPacketsLost, totalRTTCount: prevTotalRTTCount, totalRTT: prevTotalRTT, } = this.prevRTPInfo || {};
                const packetsSent = prevPacketsSent ? totalPacketsSent - prevPacketsSent : totalPacketsSent;
                const packetsLost = prevPacketsLost ? totalPacketsLost - prevPacketsLost : totalPacketsLost;
                const rttCount = (totalRTTCount && totalRTT)
                    ? (prevTotalRTTCount ? totalRTTCount - prevTotalRTTCount : totalRTTCount)
                    : -1;
                const rttTime = (totalRTTCount && totalRTT)
                    ? (prevTotalRTT ? totalRTT - prevTotalRTT : totalRTT)
                    : -1;
                const rtt = (rttCount === -1 || rttCount === 0) ? lastRTT : rttTime / rttCount;
                this.prevRTPInfo = {
                    totalPacketsSent,
                    totalPacketsLost,
                    totalRTTCount,
                    totalRTT
                };
                if (!rtt || !packetsSent || !this.statManager)
                    return;
                this.statManager.rtpDetect({ packetsSent, packetsLost, rtt });
            }
            catch (err) {
                // webrtc 可能已经结束，这个时候 getStats 会报错
                // ignore
            }
        });
        this.handleRTPStart = ({ peerconnection }) => {
            this.peerconnection = peerconnection;
            this.prevRTPInfo = null;
            if (!this.getConfig().enable)
                return;
            this.stopRTPCollection();
            this.rtpCollection();
            if (!this.statManager)
                return;
            this.statManager.startRTP();
        };
        this.handleRTPEnd = () => {
            this.stopRTPCollection();
            this.peerconnection = null;
            this.prevRTPInfo = null;
            if (!this.statManager)
                return;
            this.statManager.endRTP();
        };
        this.listenSession = () => {
            const { session } = this;
            if (!session)
                return;
            session.addListener('RTP_START', this.handleRTPStart);
            session.addListener('RTP_END', this.handleRTPEnd);
        };
        this.removeSession = () => {
            const { session } = this;
            if (!session)
                return;
            session.removeListener('RTP_START', this.handleRTPStart);
            session.removeListener('RTP_END', this.handleRTPEnd);
        };
        this.destroy = () => {
            this.end();
            this.removeRegister();
            this.removeSession();
        };
        this.options = options;
        this.init();
    }
}
export default NetworkQualityStat;
