var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import EventEmitter from 'eventemitter3';
import { isNumber } from '../helper';
var Status;
(function (Status) {
    Status[Status["INIT"] = 0] = "INIT";
    Status[Status["START"] = 1] = "START";
    Status[Status["END"] = 2] = "END";
})(Status || (Status = {}));
const statsTypeWhiteMap = {
    'inbound-rtp': true,
    'outbound-rtp': true,
    'remote-inbound-rtp': true,
    'remote-outbound-rtp': true
};
class Stats extends EventEmitter {
    constructor() {
        super();
        this.getRTCStats = () => __awaiter(this, void 0, void 0, function* () {
            const { connection } = this;
            if (!connection)
                return;
            try {
                const stats = [...(yield connection.getStats()).values()]
                    .filter(({ type }) => statsTypeWhiteMap[type])
                    .reduce((accu, curr) => (Object.assign(Object.assign({}, accu), { [curr.type]: curr })), {});
                if (Object.keys(statsTypeWhiteMap).every(key => !stats[key]))
                    return this.loop();
                const remoteInboundRtp = stats['remote-inbound-rtp'] || {};
                const outboundRtp = stats['outbound-rtp'] || {};
                const remoteOutboundRtp = stats['remote-outbound-rtp'] || {};
                const inboundRtp = stats['inbound-rtp'] || {};
                const output = {
                    jitter: remoteInboundRtp.jitter,
                    roundTripTime: remoteInboundRtp.roundTripTime,
                    totalRoundTripTime: remoteInboundRtp.totalRoundTripTime,
                    roundTripTimeMeasurements: remoteInboundRtp.roundTripTimeMeasurements,
                    packetsLost: remoteInboundRtp.packetsLost,
                    packetsReceived: remoteInboundRtp.packetsReceived,
                    packetsSent: outboundRtp.packetsSent,
                    nackCount: outboundRtp.nackCount,
                };
                const input = {
                    jitter: inboundRtp.jitter,
                    // 文档上说没有这个属性，但是这里也取一下，万一那天有了呢...(有 totalRoundTripTime 和 roundTripTimeMeasurements)
                    roundTripTime: remoteOutboundRtp.roundTripTime || inboundRtp.roundTripTime,
                    packetsLost: inboundRtp.packetsLost,
                    packetsReceived: inboundRtp.packetsReceived,
                    packetsSent: remoteOutboundRtp.packetsSent,
                    totalRoundTripTime: remoteOutboundRtp.totalRoundTripTime,
                    roundTripTimeMeasurements: remoteOutboundRtp.roundTripTimeMeasurements,
                };
                this.statList.push({
                    output,
                    input,
                });
                this.loop();
            }
            catch (err) {
                // webrtc 可能已经结束，这个时候 getStats 会报错
                // ignore
            }
        });
        this.loop = () => {
            this.timeout = setTimeout(this.getRTCStats, 1e3);
        };
        this.getAve = (list) => {
            const len = list.length;
            if (!len)
                return -1;
            return +(list.reduce((accu, curr) => accu + curr, 0) / len).toFixed(2);
        };
        this.getNumberList = (key, prop) => {
            const { statList } = this;
            return statList.map(stat => stat[key][prop] * 1000)
                .filter(vl => isNumber(vl))
                .map(vl => +vl.toFixed(2));
        };
        this.getMax = (numberList) => {
            if (!numberList.length)
                return -1;
            return Math.max(...numberList);
        };
        this.getMin = (numberList) => {
            if (!numberList.length)
                return -1;
            return Math.min(...numberList);
        };
        this.calcJitter = () => {
            const inputList = this.getNumberList('input', 'jitter');
            const outputList = this.getNumberList('output', 'jitter');
            return {
                inputJitter: {
                    maxJitter: this.getMax(inputList),
                    minJitter: this.getMin(inputList),
                    aveJitter: this.getAve(inputList),
                },
                outputJitter: {
                    maxJitter: this.getMax(outputList),
                    minJitter: this.getMin(outputList),
                    aveJitter: this.getAve(outputList),
                }
            };
        };
        this.calcAveRTT = (stat) => {
            const { totalRoundTripTime, roundTripTimeMeasurements } = stat;
            return (roundTripTimeMeasurements && isNumber(totalRoundTripTime))
                ? +((totalRoundTripTime * 1000) / roundTripTimeMeasurements).toFixed(2)
                : -1;
        };
        this.calcRTT = ({ lastInput, lastOutput }) => {
            const inputList = this.getNumberList('input', 'roundTripTime');
            const outputList = this.getNumberList('output', 'roundTripTime');
            return {
                inputRTT: {
                    maxRTT: this.getMax(inputList),
                    minRTT: this.getMin(inputList),
                    aveRTT: this.calcAveRTT(lastInput),
                },
                outputRTT: {
                    maxRTT: this.getMax(outputList),
                    minRTT: this.getMin(outputList),
                    aveRTT: this.calcAveRTT(lastOutput),
                }
            };
        };
        this.start = (connection) => {
            if (this.status !== Status.INIT)
                return;
            this.status = Status.START;
            this.connection = connection;
            this.getRTCStats();
        };
        this.getDefaultMetric = (value) => {
            return isNumber(value) ? value : -1;
        };
        this.end = () => __awaiter(this, void 0, void 0, function* () {
            if (this.status !== Status.START)
                return;
            this.connection = null;
            clearTimeout(this.timeout);
            yield this.getRTCStats();
            const { statList } = this;
            if (!statList.length) {
                this.emit('RTP_STATS_END', {
                    input: null,
                    output: null,
                });
                return;
            }
            const lastStat = statList[statList.length - 1];
            const lastInput = lastStat.input;
            const lastOutput = lastStat.output;
            const { inputJitter, outputJitter } = this.calcJitter();
            const { inputRTT, outputRTT } = this.calcRTT({ lastInput, lastOutput });
            this.emit('RTP_STATS_END', {
                input: Object.assign(Object.assign({ packetsSent: this.getDefaultMetric(lastInput.packetsSent), packetsLost: this.getDefaultMetric(lastInput.packetsLost), packetsReceived: this.getDefaultMetric(lastInput.packetsReceived) }, inputJitter), inputRTT),
                output: Object.assign(Object.assign({ packetsSent: this.getDefaultMetric(lastOutput.packetsSent), packetsLost: this.getDefaultMetric(lastOutput.packetsLost), packetsReceived: this.getDefaultMetric(lastOutput.packetsReceived), nackCount: this.getDefaultMetric(lastOutput.nackCount) }, outputJitter), outputRTT),
            });
        });
        this.status = Status.INIT;
        this.statList = [];
    }
}
export default Stats;
