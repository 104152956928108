
/**
 * @author giligili
 * @date 2023-02-20
 * @email zhimingwang@yangqianguan.com
 * @desc 回拨(一个特殊的外显号, 需要和正常的回拨区分下)
 * 在 ytalk 那边都是 INBOUND 类型, 不过它拥有新的业务场景
 * 基于这个业务场景作区分
 */

import EnumAll from 'collection-admin-web/common/constant/enum';

import mixinGetCaseDetail from '../../mixin/get-case-detail';
import BasicType from './basic-type';

const CallDialType = EnumAll.Call.DialType.TYPE;

export default class InboundType extends BasicType {

    type = 'COMPLAINT_DIAL_BACK';

    constructor(props) {
        super(props);

        this.title = '【投诉进线】';
        this.encryptedMobileNumber = this.sharedData.incomingEncryptedMobileNumber;

        this.extraData.dialType = CallDialType.COMPLAINT_DIAL_BACK;
        this.extraData.encryptedMobileNumber = this.encryptedMobileNumber;
    }

    /* @desc override */
    getSharedData() {
        const {sharedDataRet} = this;

        return sharedDataRet.promise;
    }

}

Object.assign(InboundType.prototype, mixinGetCaseDetail);
