<!-- @Author: xymo -->
<!-- @Date: 2024-08-22 -->
<!-- @Last Modified by: xymo -->
<!-- @Last Modified time: 2024-08-22 14:16:29 -->

<template>
    <div
        class="yqg-canary"
        style="margin-right: 25px;"
    >
        <a-input-search
            v-model="canaryId"
            style="display: block;"
            placeholder="设置灰度版本"
            @blur="setCanaryId"
            @search="setCanaryId"
        >
            <a-icon
                slot="enterButton"
                type="setting"
            />
        </a-input-search>
    </div>
</template>

<script>

    import {axios as http} from '@yqg/resource';

    import CommonResource from 'collection-admin-web/common/resource/common';
    import CanaryStorage from 'collection-admin-web/common/util/storage/canary';

    export default {
        name: 'YqgCanaryInput',

        data() {
            return {
                canaryId: CanaryStorage.get()
            };
        },

        created() {
            if (this.canaryId) {
                // 优先取storage的版本
                http.defaults.headers.common['fintopia-deploy-version'] = this.canaryId;
            } else {
                // storage没有版本则取接口的版本
                this.initCanaryId();
            }
        },

        methods: {
            initCanaryId() {
                CommonResource.getReleaseVersion()
                    .then(({data: {body}}) => {
                        if (body) {
                            this.canaryId = body;
                            this.setCanaryId();
                        }
                    });
            },
            setCanaryId() {
                const canaryId = (this.canaryId || '').trim();
                http.defaults.headers.common['fintopia-deploy-version'] = canaryId;
                CanaryStorage.set(canaryId);
                this.$router.go(this.$route.currentRoute);
            }
        }
    };
</script>
