/*
 * @Author: huayizhang
 * @Date: 2021-07-19 14:48:55
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-10-18 17:01:25
 */

/* eslint-disable import/no-named-as-default-member */

import Resource from '@yqg/resource';

import {deepClone} from 'yqg-common/core/ToolFunction';

import {BooleanType} from 'collection-admin-web/common/constant/boolean';

const api = {
    urlPrefix: '/admin/operation/col/case/',

    getCaseById: {
        url: 'getCaseById',
        method: 'get'
    },

    getNextCaseId: {
        url: 'getNextCaseId',
        method: 'get'
    },

    trialCaseOverdueFee: {
        url: 'trialCaseOverdueFee',
        method: 'get'
    },

    listMessageHistory: {
        url: 'listMessageHistory',
        method: 'get'
    },

    listDelayMessage: {
        url: 'listDelayMessage',
        method: 'get'
    },

    cancelDelayMessage: {
        url: 'cancelDelayMessage',
        method: 'delete'
    },

    sendMessage: {
        url: 'sendMessage',
        method: 'post'
    },

    getMessage: {
        url: 'getMessage',
        method: 'get'
    },

    getAvailableMessage: {
        url: 'getAvailableMessage',
        method: 'get'
    },

    getCollectionMessage: {
        url: 'getCollectionMessage',
        method: 'get'
    },

    getUserSelfMobileList: {
        url: 'getUserSelfMobileList',
        method: 'get'
    },

    getSendMessageMobileNumbers: {
        url: 'getSendMessageMobileNumbers',
        method: 'get'
    },

    getFundingProviderInfoView: {
        url: 'getFundingProviderInfoView',
        method: 'get'
    },

    listContact: {
        url: 'listContactV4',
        method: 'get'
    },

    assignComplaintCase: {
        url: 'assignComplaintCase',
        method: 'post'
    },

    createCaseStar: {
        url: 'createCaseStar',
        method: 'post'
    },

    cancelCaseStar: {
        url: 'cancelCaseStar',
        method: 'post'
    },

    cancelPhoneStar: {
        url: 'cancelPhoneStar',
        method: 'post'
    },

    updatePhoneBookContact: {
        url: 'updatePhoneBookContact',
        method: 'post'
    },

    addPhoneBookContact: {
        url: 'addPhoneBookContact',
        method: 'post'
    },

    listContactNames: {
        url: 'listContactNames',
        method: 'get'
    },

    listCaseTrial: {
        url: 'listCaseTrial',
        method: 'get'
    },

    trialCaseById: {
        url: 'trialCaseById',
        method: 'get'
    },

    reduceCaseById: {
        url: 'reduceCaseById',
        method: 'post'
    },

    getOrderInfo: {
        url: 'getOrderInfo',
        method: 'get'
    },

    getOfflineRepaySwitchInfo: {
        url: 'getOfflineRepaySwitchInfo',
        method: 'get'
    },

    openOfflineRepaySwitch: {
        url: 'openOfflineRepaySwitch',
        method: 'get'
    },

    getAliPaySwitchInfo: {
        url: 'getAliPaySwitchInfo',
        method: 'get'
    },

    openAliPaySwitch: {
        url: 'openAliPaySwitch',
        method: 'get'
    },

    getWechatPaySwitchInfo: {
        url: 'getWechatPaySwitchInfo',
        method: 'get'
    },

    openWechatPaySwitch: {
        url: 'openWechatPaySwitch',
        method: 'get'
    },

    checkPartialWithhold: {
        url: 'checkPartialWithhold',
        method: 'get'
    },

    getOrderDetailRepayInfo: {
        url: 'getOrderDetailRepayInfo',
        method: 'get'
    },

    listRepayReceipts: {
        url: 'listRepayReceipts',
        method: 'get'
    },

    listInstalment: {
        url: 'listInstalment',
        method: 'get'
    },

    trialSettleInAdvanceFee: {
        url: 'trialSettleInAdvanceFee',
        method: 'get'
    },

    getCouponByOrderNumber: {
        url: 'getCouponByOrderNumber',
        method: 'get'
    },

    openSettleInAdvance: {
        url: 'openSettleInAdvance',
        method: 'post'
    },

    withhold: {
        url: 'withhold',
        method: 'post'
    },

    listDemandLetterProcess: {
        url: 'listDemandLetterProcess',
        method: 'get'
    },

    sendDemandLetter: {
        url: 'sendDemandLetter',
        method: 'post'
    },

    listDialBackInfo: {
        url: 'listDialBackInfo',
        method: 'get'
    },

    getRepairedPhoneNumberInfo: {
        url: 'getRepairedPhoneNumberInfo',
        method: 'get'
    },

    getRepairedCallNumberInfo: {
        url: 'getRepairedCallNumberInfo',
        method: 'get'
    },

    /* ---- 欺诈用户 start ---- */
    saveRiskBlackList: {
        url: 'saveRiskBlackList',
        method: 'post'
    },

    queryRiskBlackListResult: {
        url: 'queryRiskBlackListResult',
        method: 'get'
    },

    getRiskRejectReason: {
        url: 'getRiskRejectReason',
        method: 'get'
    },
    /* ---- 欺诈用户 end ---- */

    /* ---- h5还款 start ---- */
    getLastRepayMessage: {
        url: 'getLastRepayMessage',
        method: 'get'
    },

    generateRepayLink: {
        url: 'generateRepayLink',
        method: 'post'
    },

    sendOfflineRepayMessage: {
        url: 'sendOfflineRepayMessage',
        method: 'post'
    },

    getGenerateRepayCodeHistory: {
        url: 'getGenerateRepayCodeHistory',
        method: 'get'
    },

    /* ---- h5还款 end ---- */

    uploadRepayReceipt: {
        url: 'uploadRepayReceipt',
        method: 'post'
    },

    listRepayAccountType: {
        url: 'listRepayAccountType',
        method: 'get'
    },

    supportUploadRepayReceipt: {
        url: 'supportUploadRepayReceipt',
        method: 'get'
    },

    checkIfNeedFeedBackAlert: {
        url: 'checkIfNeedFeedBackAlert',
        method: 'get'
    },

    listAvailableBankAccount: {
        url: 'listAvailableBankAccount',
        method: 'get'
    },

    getPublicRepaymentInfo: {
        url: 'getPublicRepaymentInfo',
        method: 'get'
    },

    getCaseInfo: {
        url: 'getCaseInfo',
        method: 'get'
    },

    // 还款试算
    trial: {
        url: '/admin/operation/col/repayment/trial',
        method: 'post'
    },

    recommendRepaymentChannel: {
        url: '/admin/operation/col/repayment/getRecommendRepayChannel',
        method: 'get'
    },

    // 查询微信支付 和 支付宝支付开关
    queryRepaymentSwitchStatus: {
        url: '/admin/operation/col/case/queryRepaymentSwitchStatus',
        method: 'post'
    },

    // 开关微信支付 或 支付宝支付
    openRepaymentSwitch: {
        url: '/admin/operation/col/case/openRepaymentSwitch',
        method: 'post'
    },

    /** 获取服务记录 */
    getCustomerServiceList: {
        url: 'getCustomerServiceList',
        method: 'post'
    }
    ,
    /** 点击提示日志记录 */
    getCaseTagByCaseIdAndConfigDWLogger: {
        url: '/admin/operation/col/case/getCaseTagByCaseIdAndConfigDWLogger',
        method: 'post'
    },

    /** 点击提前结清试算  */
    settleInAdvanceTrial: {
        url: '/admin/operation/col/case/settleInAdvance/trial',
        method: 'post'
    },
};

const resource = Resource.create(api);
const TODAY_LAST_CALL_RESULT_TAG = 'CONNECT';
const handleparams = params => {
    const result = deepClone(params);

    if (result.calledToday === TODAY_LAST_CALL_RESULT_TAG) {
        delete result.calledToday;
        result.todayLastCallResult = BooleanType.TRUE;
    } else if (result.todayLastCallResult) {
        delete result.todayLastCallResult;
    }

    return result;
};

export default {
    ...resource,

    getCaseInfo: params => resource.getCaseById(params).then(({data: {body: {dataJson}}}) => {
        try {
            const {dataList = {}} = JSON.parse(dataJson);

            return {data: {body: {orderInfo: dataList}}};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    getOrderInfo: params => resource.getOrderInfo(params).then(({data: {body: {dataJson}}}) => {
        try {
            const {dataList = {}} = JSON.parse(dataJson);

            return {data: {body: {orderInfo: dataList}}};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    listInstalment: params => resource.listInstalment(params).then(({data: {body: {dataJson}}}) => {
        try {
            return {data: {body: JSON.parse(dataJson)}};
        } catch (err) {
            return Promise.reject(err);
        }
    }),

    /* @desc 获得该案件的所有联系人 */
    listAllContact: ({params: {caseId}}) => resource.listContact({params: {caseId}}),

    listYqgContact: ({params}) => resource.listContact({params: handleparams(params)})
};
