/**
 * @Author: xymo
 * @Date: 2021-04-13
 * @Last Modified by: yefenghan
 * @Last Modified time: 2024-10-16 11:29:35
 */

export const CODE_SUCCESS = 0;

export const YQG_STATUS_CODE_COLLECTION_LONGTIME_NO_OPERATION_KICKOUT = 13019; // 长时间未作业，请重新登录

export const CODE_NOT_LOGIN = 114004;
export const YQG_STATUS_CODE_COLLECTION_LOGIN_NEED_CAPTCHA = 114018;
export const YQG_STATUS_CODE_COLLECTION_LOGIN_CAPTCHA_WRONG = 114017;
export const YQG_STATUS_CODE_COLLECTION_LOGIN_NEED_SMS_VERIFY = 114198;

export const YQG_STATUS_CODE_COLLECTION_MANUAL_ASSIGN_CASE_EXCEPTION = 114224; // 添加催记成功，留案失败
export const YQG_STATUS_CODE_COL_IVR_ASSIGN_CASE_EXCEPTION = 118009; // 添加催记成功，机器人转人工留案失败:{0}

export const YQG_STATUS_CODE_COLLECTION_MOBILE_VERIFICATION_CODE_EXPIRED = 114201; // 短信验证码已失效
export const YQG_STATUS_CODE_COLLECTION_MOBILE_INVALID_VERIFICATION_CODE = 114202; // 验证码不正确
export const YQG_STATUS_CODE_COLLECTION_MOBILE_VERIFY_COUNT_EXCEED_LIMIT = 114203; // 短信验证码验证次数超过限制

export const YQG_STATUS_CODE_COLLECTION_CASE_COLLECTOR_NOT_MATCH = 114020; //当前案件未分配给您，无法操作
export const YQG_STATUS_CODE_COLLECTION_SEND_TOO_MANY_MESSAGE = 114077; // 短信发送失败，超出数量限制

export const YQG_STATUS_CODE_COLLECTION_NOTIFICATION_REPEAT_PROCESS = 114097; // 请勿重复处理通知

export const YQG_STATUS_CODE_COLLECTON_DATA_ACCESS_RESTRICTED = 114110; // 无权访问这条数据
export const YQG_STATUS_CODE_COLLECTON_OPERATION_WARN = 114112; // 操作警告	
export const YQG_STATUS_CODE_COLLECTION_MESSAGE_EXCEED_SAME_CONTENT_LIMIT = 114147; // 相同文案短信超过当日发送上限
export const YQG_STATUS_CODE_COLLECTON_DIAL_CONTACT_BEFORE_VALID_SELF = 114158; // 请确认今天已经联系过借款人
export const YQG_STATUS_CODE_COLLECTION_ADMIN_PASSWORD_BEYOND_VALIDITY = 114178; // 当前密码已经超过有效期，为保证账户安全，请先修改密码
export const YQG_STATUS_CODE_COLLECTON_WEIGHT_OVER_LIMIT = 114180; // 外呼号码权重和超过限制
export const YQG_STATUS_CODE_COLLECTION_CONNECTED_OVER_LIMIT_PER_DAY = 114183; // 该号码{0}今日拨打次数或接通次数已达上限
export const YQG_STATUS_CODE_COLLECTON_UNAVAILABLE_FOR_ENTERPRISE_WECHAT = 114212; // 不支持发送微信提醒短信
export const YQG_STATUS_CODE_COLLECTON_MESSAGE_EXCEED_SAME_MOBILE_LIMIT = 114261; // 当前手机号短信超过当日发送上限
export const YQG_STATUS_CODE_COLLECTON_BUBBLE_SIGN_IN_FIRST = 114269; // 冒泡提示
